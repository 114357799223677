import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://wedding-invitation-back.onrender.com/api",
  }),
  endpoints: (builder) => ({
    signinUser: builder.mutation({
      query: (body: { email: string; password: string }) => {
        return {
          url: "/auth/signin",
          method: "post",
          body,
        };
      },
    }),
  }),
});

export const { useSigninUserMutation } = authApi;
