import instance from "../app/axios";
import IContent from "../types/Content";

const getAll = () => {
  return instance.get<Array<IContent>>("/invite");
};

const get = (slug: any) => {
  return instance.get<IContent>(`/invite/${slug}`);
};

const create = (data: IContent) => {
  return instance.post<IContent>("/invite", data);
};

const update = (id: any, data: IContent) => {
  return instance.patch<any>(`/invite/${id}`, data);
};

const remove = (id: any) => {
  return instance.delete<any>(`/invite/${id}`);
};

const ContentService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default ContentService;
