import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ContentService from "../../services/ContentService";
import IContent from "../../types/Content";

const CreateContent = () => {
  const initialValues = {
    _id: null,
    cardName: "S & N",
    title: "",
    man: "Sardor",
    woman: "Nazokat",
    day: "20 May at 18:00",
    restaurant: "Royal Rose Restaurant",
    tableNumber: "",
    chairNumber: "",
    slug: "",
    text: "Can't wait to see you",
  };

  const navigate = useNavigate();

  const [card, setCard] = useState<IContent>(initialValues);
  const [loading, setLoading] = useState<Boolean>(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setCard({ ...card, [name]: value });
  };

  const createCard = () => {
    setLoading(true);
    var data = {
      cardName: card.cardName,
      title: card.title,
      man: card.man,
      woman: card.woman,
      day: card.day,
      restaurant: card.restaurant,
      tableNumber: card.tableNumber,
      chairNumber: card.chairNumber,
      slug: card.slug,
      text: card.text,
    };

    ContentService.create(data)
      .then((response: any) => {
        setCard({
          _id: response.data._id,
          cardName: response.data.cardName,
          title: response.data.title,
          man: response.data.man,
          woman: response.data.woman,
          day: response.data.day,
          restaurant: response.data.restaurant,
          tableNumber: response.data.tableNumber,
          chairNumber: response.data.chairNumber,
          slug: response.data.slug,
          text: response.data.text,
        });
        if (response.status === 200) {
          setLoading(false);
          navigate("/");
        }
      })
      .catch((e: any) => {
        console.log(e);
        setLoading(false);
        toast.error(e.response.data.message);
      });
  };

  useEffect(() => {}, [navigate]);

  return (
    <div>
      <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
        Create card
      </h1>
      <div className="modal-body py-10 px-lg-17">
        <div className="scroll-y me-n7 pe-7">
          <div className="fv-row mb-7 fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Card Name</label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="cardName"
              value={card.cardName}
              onChange={handleInputChange}
            />
            <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <div className="fv-row mb-7 fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Title</label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="title"
              value={card.title}
              onChange={handleInputChange}
            />
            <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <div className="row g-9 mb-7">
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Man</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="man"
                onChange={handleInputChange}
                value={card.man}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Woman</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="woman"
                onChange={handleInputChange}
                value={card.woman}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Day</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="day"
                onChange={handleInputChange}
                value={card.day}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Restaurant</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="restaurant"
                onChange={handleInputChange}
                value={card.restaurant}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Table Number</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="tableNumber"
                onChange={handleInputChange}
                value={card.tableNumber}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Chair Number</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="chairNumber"
                onChange={handleInputChange}
                value={card.chairNumber}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Slug</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="slug"
                onChange={handleInputChange}
                value={card.slug}
              />
              <div className="fv-plugins-message-container">
                Please make sure the slug you enter is unique and not already
                used.
              </div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Text</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="text"
                onChange={handleInputChange}
                value={card.text}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row justify-content-end">
            <button
              className="btn btn-primary d-flex ml-auto"
              style={{
                width: "fit-content",
              }}
              onClick={createCard}
            >
              Submit
              <span className={`indicator-${loading ? "label" : "progress"}`}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateContent;
