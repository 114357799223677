import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IContent from "./../../types/Content";
import ContentService from "./../../services/ContentService";

const UpdateContent = () => {
  const initialValues = {
    _id: null,
    cardName: "S & N",
    title: "",
    man: "Sardor",
    woman: "Nazokat",
    day: "20 May at 18:00",
    restaurant: "Royal Rose Restaurant",
    tableNumber: "",
    chairNumber: "",
    slug: "",
    text: "Can't wait to see you",
  };

  const { slug } = useParams();
  let navigate = useNavigate();

  const [currentCard, setCurrentCard] = useState<IContent>(initialValues);
  const [loading, setLoading] = useState<Boolean>(false);

  const getTutorial = (slug: string) => {
    ContentService.get(slug)
      .then((response: any) => {
        setCurrentCard(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (slug) getTutorial(slug);
  }, [slug]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCurrentCard({ ...currentCard, [name]: value });
  };

  const updateCard = () => {
    setLoading(true);
    ContentService.update(currentCard?._id, currentCard)
      .then((response: any) => {
        if (response.status === 200) {
          setLoading(false);
          navigate("/");
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return currentCard ? (
    <div>
      <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
        Update card
      </h1>
      <div className="modal-body py-10 px-lg-17">
        <div className="scroll-y me-n7 pe-7">
          <div className="fv-row mb-7 fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Card Name</label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="cardName"
              value={currentCard.cardName}
              onChange={handleInputChange}
            />
            <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <div className="fv-row mb-7 fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Title</label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="title"
              value={currentCard.title}
              onChange={handleInputChange}
            />
            <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <div className="row g-9 mb-7">
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Man</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="man"
                onChange={handleInputChange}
                value={currentCard.man}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Woman</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="woman"
                onChange={handleInputChange}
                value={currentCard.woman}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Day</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="day"
                onChange={handleInputChange}
                value={currentCard.day}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Restaurant</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="restaurant"
                onChange={handleInputChange}
                value={currentCard.restaurant}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Table Number</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="tableNumber"
                onChange={handleInputChange}
                value={currentCard.tableNumber}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Chair Number</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="chairNumber"
                onChange={handleInputChange}
                value={currentCard.chairNumber}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Slug</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="slug"
                onChange={handleInputChange}
                value={currentCard.slug}
              />
              <div className="fv-plugins-message-container">
                Please make sure the slug you enter is unique and not already
                used.
              </div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Text</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="text"
                onChange={handleInputChange}
                value={currentCard.text}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row justify-content-end">
            <button
              className="btn btn-primary d-flex ml-auto"
              style={{
                width: "fit-content",
              }}
              onClick={updateCard}
            >
              Submit
              <span className={`indicator-${loading ? "label" : "progress"}`}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>Not found!</div>
  );
};

export default UpdateContent;
