import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ContentService from "../../services/ContentService";
import IContent from "../../types/Content";
// import { Link } from "react-router-dom";

const Content = () => {
  const [data, setData] = useState<Array<IContent>>([]);
  const [currentData, setCurrentData] = useState<IContent | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [list, setList] = useState<Array<IContent>>(data);

  const handleInputChange = (e: any) => {
    let searchItems = list;

    const { value } = e.target;
    setSearch(value);
    if (search) {
      searchItems = searchItems.filter(
        (item) =>
          item.slug.toLowerCase().search(search.toLowerCase().trim()) !== -1 ||
          item.title.toLowerCase().search(search.toLowerCase().trim()) !== -1
      );
    }
    setData(searchItems);
  };

  const getData = () => {
    setLoading(true);
    ContentService.getAll()
      .then((response: any) => {
        setData(response.data);
        setList(response.data);
        setLoading(false);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const setActiveData = (card: IContent, index: number) => {
    setCurrentData(card);
    setCurrentIndex(index);
  };

  const deleteCard = (id: any) => {
    setLoading(true);
    ContentService.remove(id)
      .then((response: any) => {
        let newData = data.filter((d) => d._id !== id);
        setData(newData);
        if (response.status === 200) {
          setLoading(false);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <div className="list row">
      <div className="col-12">
        <label className="fs-6 fw-bold mb-2">Search</label>
        <input
          type="text"
          className="form-control form-control-solid"
          placeholder=""
          name="cardName"
          value={search}
          onChange={handleInputChange}
        />
      </div>
      <div className="col-md-8">
        <h3>Cards List</h3>
        {!loading ? (
          <ul className="card-body list-group">
            {data &&
              data?.map((res, index) => (
                <li
                  className={
                    "d-flex align-items-center justify-content-between list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => setActiveData(res, index)}
                  key={index}
                >
                  {res.slug}
                  <div
                    style={{
                      marginRight: "30px",
                      marginLeft: "auto",
                    }}
                  >
                    {res.tableNumber}
                  </div>
                  {/* {console.log("res", res)} */}
                  <div>
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://sardor-nazokat.wedding/invitation/${res.slug}`
                        );
                        toast.info(
                          `Invitation to ${res.slug} copied to clipboard`
                        );
                      }}
                    >
                      Copy
                    </button>
                    <Link
                      to={`/update/${res.slug}`}
                      className="btn btn-light-primary btn-sm ml-5"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => deleteCard(res._id)}
                      className="btn btn-light-danger btn-sm ml-5"
                    >
                      delete
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        )}
      </div>
      <div className="col-md-4">
        {currentData ? (
          <div>
            <h4>Card</h4>
            <div>
              <label>
                <strong>Title:</strong>
              </label>{" "}
              {currentData.title}
            </div>
            <div>
              <label>
                <strong>Man:</strong>
              </label>{" "}
              {currentData.man}
            </div>
            <div>
              <label>
                <strong>Woman:</strong>
              </label>{" "}
              {currentData.woman}
            </div>
            <div>
              <label>
                <strong>Day:</strong>
              </label>{" "}
              {currentData.day}
            </div>
            <div>
              <label>
                <strong>Restaurant:</strong>
              </label>{" "}
              {currentData.restaurant}
            </div>
            <div>
              <label>
                <strong>Table number:</strong>
              </label>{" "}
              {currentData.tableNumber}
            </div>
            <div>
              <label>
                <strong>Chair Number:</strong>
              </label>{" "}
              {currentData.chairNumber}
            </div>
            <div>
              <label>
                <strong>Slug:</strong>
              </label>{" "}
              {currentData.slug}
            </div>
            <div>
              <label>
                <strong>Text:</strong>
              </label>{" "}
              {currentData.text}
            </div>
          </div>
        ) : data ? (
          <div>
            <br />
            <p>Please click on a Card...</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Content;
