import axios, { AxiosRequestConfig } from "axios";

const token = localStorage.getItem("token") || "";

const instance = axios.create({
  baseURL: "https://wedding-invitation-back.onrender.com/api",
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    config.headers = { Authorization: `Bearer ${token ? token : ""}` };
    return config;
  }
);
export default instance;
