import CreateContent from "../Content/CreateContent";
import Content from "./../Content/index";
import UpdateContent from "./../Content/UpdateContent";
export const routes = [
  {
    path: "/",
    element: <Content />,
  },
  {
    path: "/create",
    element: <CreateContent />,
  },
  {
    path: "/update/:slug",
    element: <UpdateContent />,
  },
];
