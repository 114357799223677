import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { defaultState } from "../redux/authSlice";

const Header = () => {
  const dispatch = useAppDispatch();

  const logOut = () => {
    console.log("logOut");
    dispatch(defaultState());
    window.location.reload();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  return (
    <nav className="header align-items-stretch navbar-dark bg-dark">
      <div className="container d-flex align-items-stretch justify-content-between">
        <div className="container-fluid d-flex flex-stack">
          <Link to="/">
            <h1 className="d-flex align-items-center text-light fw-bolder my-1 fs-3">
              Dashboard
            </h1>
          </Link>
        </div>
        <div className="d-flex align-items-center py-1">
          <Link to="/create" className="btn btn-sm btn-primary">
            Create
          </Link>
          <button
            className="btn btn-sm btn-flex btn-light btn-active-danger fw-bolder"
            style={{
              whiteSpace: "nowrap",
              marginLeft: "10px",
            }}
            onClick={logOut}
          >
            Log out
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Header;
