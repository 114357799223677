/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import Login from "./pages/auth/Login";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { Navigate } from "react-router-dom";
import NotFound from "./pages/auth/NotFound";
import { routes } from "./pages/routes/index";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { useEffect } from "react";
import { setUser } from "./redux/authSlice";
import Layout from "./components/Layout";

function App() {
  const { role, token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const token = localStorage.getItem("token") || "";
    if (user && token) {
      dispatch(
        setUser({
          token: token,
          name: user?.name,
          email: user?.email,
          role: user?.role,
        })
      );
    }
  }, []);

  return (
    <Routes>
      {!token && role !== "Admin" ? (
        <>
          <Route path="login" element={<Login />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="*" element={<NotFound />} />
        </>
      ) : (
        <Route path="/" element={<Layout />}>
          {routes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={<PrivateRoute>{route.element}</PrivateRoute>}
            />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      )}
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
}

export default App;
